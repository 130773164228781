import config from "../../config/config";
import { queryBuilder } from "../../utils/helper";

const backendUrl = config.backendUrl;

export const fetchCompanyList = (token) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_FETCH_LOADING",
    });
    fetch(backendUrl + "/company", {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "COMPANY_FETCH_SUCCESS",
              payload: data?.data,
            });
          } else {
            dispatch({
              type: "COMPANY_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "COMPANY_FETCH_ERROR",
        });
      });
  };
};

export const fetchNLList = (token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_FETCH_LOADING",
    });
    fetch(backendUrl + "/nl", {
      method: "get",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_FETCH_SUCCESS",
              payload: data?.data,
            });
          } else {
            dispatch({
              type: "NL_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NL_FETCH_ERROR",
        });
      });
  };
};

export const fetchNLData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_DATA_FETCH_LOADING",
    });
    fetch(backendUrl + "/fetchNL", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_DATA_FETCH_SUCCESS",
              payload: data?.data,
              isDynamic: data?.isDynamic,
            });
          } else {
            dispatch({
              type: "NL_DATA_FETCH_ERROR",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "NL_DATA_FETCH_ERROR",
        });
      });
  };
};

export const updateNLData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_DATA_UPDATE_LOADING",
    });
    fetch(backendUrl + "/updateNL", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_DATA_UPDATE_SUCCESS",
              payload: data?.data,
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "NL_DATA_UPDATE_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NL_DATA_UPDATE_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchRowColumns = (nlId, query, token) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NL_ROW_COL_LOADING",
    });
    const url = queryBuilder(`/nl/${nlId}/company/rows/columns`, query);
    fetch(backendUrl + url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "FETCH_NL_ROW_COL_SUCCESS",
              payload: data.data,
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "FETCH_NL_ROW_COL_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "FETCH_NL_ROW_COL_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const updateNLRowColumns = (id, body, token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_ROW_COLUMN_UPDATE_LOADING",
    });
    fetch(backendUrl + `/nl/${id}/rows/columns`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_ROW_COLUMN_UPDATE_SUCCESS",
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "NL_ROW_COLUMN_UPDATE_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NL_ROW_COLUMN_UPDATE_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchRowColumnsCompany = (
  nlId,
  dimension = "row",
  name,
  query,
  token
) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_NL_ROW_COL_COMPANY_LOADING",
    });
    const url = queryBuilder(
      `/nl/${nlId}/${dimension}/${name}/companies`,
      query
    );
    fetch(backendUrl + url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "FETCH_NL_ROW_COL_COMPANY_SUCCESS",
              payload: data.data,
              message: data?.message || "Company updated Successfully",
            });
          } else {
            dispatch({
              type: "FETCH_NL_ROW_COL_COMPANY_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "FETCH_NL_ROW_COL_COMPANY_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const updateNLRowColumnsCompanies = (
  nlId,
  dimension,
  dimension_name,
  body,
  token
) => {
  return (dispatch) => {
    dispatch({
      type: "NL_ROW_COLUMN_UPDATE_COMPANIES_LOADING",
    });
    fetch(backendUrl + `/nl/${nlId}/${dimension}/${dimension_name}/companies`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_ROW_COLUMN_UPDATE_COMPANIES_SUCCESS",
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "NL_ROW_COLUMN_UPDATE_COMPANIES_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NL_ROW_COLUMN_UPDATE_COMPANIES_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const uploadNlPdf = (
  companyId,
  year,
  quarter,
  isPdf,
  forceUpdate,
  particularNL,
  nlId,
  file,
  token
) => {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_NL_PDF_LOADING",
    });
    let formData = new FormData();
    formData.append("company_id", companyId);
    formData.append("year", year);
    formData.append("quarter", quarter);
    formData.append("is_pdf", isPdf);
    formData.append("force_update", forceUpdate);
    formData.append("particular_nl", particularNL);
    formData.append("nl_id", nlId);
    formData.append("file", file);
    fetch(backendUrl + "/nl/pdf/upload", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "UPLOAD_NL_PDF_SUCCESS",
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "UPLOAD_NL_PDF_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "UPLOAD_NL_PDF_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchRawNLData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_RAW_DATA_FETCH_LOADING",
    });
    fetch(backendUrl + "/fetch/nl/raw", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_RAW_DATA_FETCH_SUCCESS",
              payload: data?.data,
              isRaw: data?.isRaw,
            });
          } else {
            dispatch({
              type: "NL_RAW_DATA_FETCH_ERROR",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "NL_RAW_DATA_FETCH_ERROR",
        });
      });
  };
};

export const updateRawNLData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "NL_RAW_DATA_UPDATE_LOADING",
    });
    fetch(backendUrl + "/update/nl/raw", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "NL_RAW_DATA_UPDATE_SUCCESS",
              payload: data?.data,
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "NL_RAW_DATA_UPDATE_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NL_RAW_DATA_UPDATE_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const setReducer = (body) => {
  return (dispatch) => {
    dispatch({ type: "RESET_REDUCER", payload: body });
  };
};

export const deleteNLData = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "DELETE_NL_DATA_LOADING",
    });
    fetch(backendUrl + "/nl/delete", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
            message: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "DELETE_NL_DATA_SUCCESS",
              message: data?.message,
            });
          } else {
            dispatch({
              type: "DELETE_NL_DATA_ERROR",
              message: data?.message,
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "DELETE_NL_DATA_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchDynamicNL = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_DYNAMIC_NL_LOADING",
    });
    fetch(backendUrl + "/fetch/nl/dynamic", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "FETCH_DYNAMIC_NL_SUCCESS",
              payload: data?.data,
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "FETCH_DYNAMIC_NL_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "FETCH_DYNAMIC_NL_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const editDynamicNL = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_DYNAMIC_NL_LOADING",
    });
    fetch(backendUrl + "/update/nl/dynamic", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "EDIT_DYNAMIC_NL_SUCCESS",
              message: data?.message || "NL Data updated Successfully",
            });
          } else {
            dispatch({
              type: "EDIT_DYNAMIC_NL_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "EDIT_DYNAMIC_NL_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const deleteDynamicNL = (body, token) => {
  return (dispatch) => {
    dispatch({
      type: "DELETE_DYNAMIC_NL_LOADING",
    });
    fetch(backendUrl + "/delete/nl/dynamic", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "DELETE_DYNAMIC_NL_SUCCESS",
              message: data?.message || "NL Data deleted Successfully",
            });
          } else {
            dispatch({
              type: "DELETE_DYNAMIC_NL_ERROR",
              message: data?.message || "Unexpected Error",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "DELETE_DYNAMIC_NL_ERROR",
          message: "Unexpected Error",
        });
      });
  };
};

export const fetchNLForceMapDetails = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_NL_FORCE_MAP_LOADING" });
    var url = backendUrl + "/nl/dynamic/mismatch/fetch";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "FETCH_NL_FORCE_MAP_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "FETCH_NL_FORCE_MAP_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "FETCH_NL_FORCE_MAP_ERROR",
        });
      });
  };
};

export const addNLForceMap = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "ADD_NL_FORCE_MAP_LOADING" });
    var url = backendUrl + "/nl/dynamic/mismatch/map";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "ADD_NL_FORCE_MAP_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "ADD_NL_FORCE_MAP_ERROR",
              message: data.message,
            });
          }
        })
      )
      .catch((err) => {
        console.error("Error", err);
        dispatch({
          type: "ADD_NL_FORCE_MAP_ERROR",
        });
      });
  };
};
