import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { DataTableHead } from "./DataTableHead";
import { DataTableToolbar } from "./DataTableToolbar";
import {
  checkNumeric,
  getComparator,
  stableSort,
} from "../../../utils/tableHelper";
import { Icon, IconButton, Skeleton } from "@mui/material";

export default function DataTable({
  data = [],
  enableToolbar = false,
  showHeader = true,
  hiddenCols = [],
  selectable = false,
  clipColumns = [],
  minRowInPage = null,
  defaultSortOrder = "desc",
  loading = null,
  bulkActions = [],
  columns = null,
  filterOrders = {},
  colFunction = {},
  rowActions = [],
  onPaginateNext = null,
  totalCount = 0,
}) {
  const CLIP_LENGTH = 12;
  const [order, setOrder] = React.useState(defaultSortOrder);
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(minRowInPage || 5);
  const [headCells, setHeadCells] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState(data);
  const [filters, setFilters] = React.useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPaginateNext(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onPaginateNext(0, parseInt(event.target.value, 10));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty data.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  React.useEffect(() => {
    let firstNuCol = null;
    if (data?.length > 0) {
      let cols = columns || Object.keys(data[0]);
      cols = cols?.filter((col) => !hiddenCols.includes(col));
      let heads = cols?.map((col, index) => {
        let ob = {
          label: col,
          id: col,
        };
        if (checkNumeric(data[0][col])) {
          if (!firstNuCol) {
            firstNuCol = col;
          }
          ob["isNumeric"] = true;
        } else {
          ob["isNumeric"] = false;
        }
        if (clipColumns?.includes(col)) {
          ob["clip"] = true;
        }
        return ob;
      });
      setHeadCells(heads);
      setOrderBy(firstNuCol);
    }
    setFilters({});
    setFilteredData(data);
  }, [data]);

  const changeFilters = (filter) => {
    console.log("FILTER", filter);
    setFilters({ ...(filter || {}) });
    let temp = filterData(data, filter);
    console.log("TEMP", temp);
    setFilteredData(temp);
  };

  const filterData = (data, filters) => {
    let tempData = data;
    let cols = Object.keys(filters);
    console.log("COLS", cols, filters);
    for (let i = 0; i < cols.length; i++) {
      let col = cols[i];
      let colData = Object.keys(filters[col])?.filter(
        (row) => filters[col][row],
      );
      console.log("colData", colData);
      tempData = tempData?.filter((d) => !colData.includes(String(d[col])));
    }
    return tempData;
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, data, filteredData],
  );

  const getVal = (head, row, fullView = false) => {
    if (colFunction && colFunction[head.label]) {
      if (head.isNumeric) {
        return colFunction[head.label](row).toFixed(2);
      } else if (head?.clip && !fullView) {
        return (
          String(colFunction[head.label](row))?.slice(0, CLIP_LENGTH) + "..."
        );
      } else {
        return String(colFunction[head.label](row));
      }
    } else {
      if (head.isNumeric) {
        return Number(row[head.label]).toFixed(2);
      } else if (head?.clip && !fullView) {
        return String(row[head.label])?.slice(0, CLIP_LENGTH) + "...";
      } else {
        return String(row[head.label]);
      }
    }
  };
  // console.log("DATA", data);
  // console.log("HEADS", headCells);

  return (
    <Box sx={{ width: "100%" }}>
      {loading !== null && loading ? (
        <Skeleton variant="rectangular" height={118} />
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }}>
          {enableToolbar && (
            <DataTableToolbar
              bulkActions={bulkActions}
              selected={selected}
              data={data}
              headCells={headCells}
              changeFilters={changeFilters}
              filters={filters}
              filterOrders={filterOrders}
            />
          )}
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              {showHeader && (
                <DataTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredData.length}
                  headCells={headCells}
                  selectable={selectable}
                  rowActions={rowActions}
                />
              )}

              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={
                        selectable
                          ? (event) => handleClick(event, row.id)
                          : () => {}
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={selectable && { cursor: "pointer" }}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      {rowActions && rowActions.length > 0 && (
                        <TableCell
                          padding="checkbox"
                          style={{ width: rowActions.length * 50 }}
                        >
                          {rowActions.map((action) => (
                            <IconButton
                              size="small"
                              style={{ marginLeft: 8 }}
                              onClick={() => {
                                action.onClick(row);
                              }}
                            >
                              <Icon size="small">{action.icon}</Icon>
                            </IconButton>
                          ))}
                        </TableCell>
                      )}

                      {headCells?.map((head, headIndex) =>
                        headIndex === 0 ? (
                          <TableCell
                            // component="th"
                            id={labelId}
                            scope="row"
                            padding="normal"
                            title={getVal(head, row, true)}
                            key={"head-i-" + headIndex}
                            style={{ minWidth: "100px" }}
                          >
                            {getVal(head, row)}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={"head-i-" + headIndex}
                            title={getVal(head, row, true)}
                            align={head.isNumeric ? "right" : "left"}
                            padding="normal"
                          >
                            {getVal(head, row)}
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Box>
  );
}
