import { combineReducers } from "redux";
import authReducer from "./authReducer";
import uploadReducer from "./uploadReducer";
import NISReducer from "./NISReducer";
import SMReducer from "./SMReducer";
import announcementReducer from "./announceReducer";
import stockPulseReducer from "./stockPulseReducer";
import CombineReducer from "./combineReducer";
import TranscriptReducer from "./transcriptReducer";
import concallReducer from "./concallReducer";
import adminReducer from "./adminReducer";
import auditReducer from "./AuditReducer";
import NLReducer from "./NLReducer";
import nlFixReducer from "./nlFixReducer";
import lifeReducer from "./lifeReducer";
import jobReducer from "./jobReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  uploadedDataResponse: uploadReducer,
  NIS: NISReducer,
  SM: SMReducer,
  announcement: announcementReducer,
  stockPulse: stockPulseReducer,
  combine: CombineReducer,
  transcript: TranscriptReducer,
  concall: concallReducer,
  admin: adminReducer,
  audit: auditReducer,
  nl: NLReducer,
  nlFix: nlFixReducer,
  life: lifeReducer,
  job: jobReducer,
});

export default rootReducer;
