import { TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function EditModal({
  row,
  status,
  changeStatus,
  info,
  changeInfo,
  onSave,
}) {
  const settingsMeta = useSelector((state) => state.auth.settingsMeta);
  // console.log("row", settingsMeta);
  const meta = settingsMeta
    ? settingsMeta?.find((item) => item.name === row?.name)?.data
    : null;

  console.log("row", row, meta);

  return (
    <div>
      <div id="job-modal" className="modal modal-fixed-footer">
        <div className="row modal-content">
          <div className="row modal-title">
            <h5 style={{ color: "rgb(93, 27, 143)" /* , paddingTop:20 */ }}>
              Job Detail
            </h5>
          </div>
          <div className="col s4">
            <div>
              <strong>Name</strong>
            </div>
            <div>{row?.name}</div>
            <div>
              <strong>NL</strong>
            </div>
            <div>{row?.nl?.lable}</div>
            <div>
              <strong>Company</strong>
            </div>
            <div>{row?.company?.name}</div>

            <div>
              <strong>Created At</strong>
            </div>
            <div>{row?.createdAt}</div>
            <div>
              <strong>Updated At</strong>
            </div>
            <div>{row?.updatedAt}</div>
          </div>
          <div className="col s8">
            <div className="col s12">
              <label>Select status</label>
              {meta && meta?.status_list ? (
                <select
                  id="selectedFilter"
                  className="browser-default custom-select"
                  onChange={changeStatus}
                  value={status}
                >
                  <option disabled selected value="">
                    Set status
                  </option>
                  <option value="pending">pending</option>
                  {meta?.status_list?.map((stat) => (
                    <option value={stat}>{stat}</option>
                  ))}
                </select>
              ) : (
                <input />
              )}
            </div>
            <div className="col s12">
              <div>
                <label>Set Info</label>
              </div>
              <div>
                <TextField
                  fullWidth={true}
                  multiline={true}
                  value={info}
                  onChange={changeInfo}
                  minRows={10}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button className="modal-close waves-effect waves btn-flat">
            CANCEL
          </button>
          <button
            type="submit"
            form="config"
            className="waves-effect waves btn-flat"
            onClick={onSave}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
