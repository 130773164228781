import { Button, Chip, Icon, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DataTable from "../../layout/DataTableServer/DataTable";
import {
  GET_JOB_LIST_ERROR,
  GET_JOB_LIST_LOADING,
  GET_JOB_LIST_SUCCESS,
  UPDATE_JOB_ERROR,
  UPDATE_JOB_SUCCESS,
} from "../../../store/constants/jobConstants";
import { commonAction } from "../../../store/action/commonAction";
import EditModal from "./EditModal";
import M from "materialize-css";
import { JOB_REDUCER_SET } from "../../../store/constants/commonConstants";
import CreateModal from "./CreateModal";

export default function JobReport() {
  const dispatch = useDispatch();
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const [filters, setFilters] = useState([]);
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const companies = useSelector((state) => state.NIS.companyList);
  const [filterType, setFilterType] = useState(null);
  const [filterVal, setFilterVal] = useState("");
  const [editRow, setEditorRow] = useState(null);
  const [status, setStatus] = useState(null);
  const [info, setInfo] = useState(null);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const jobs = useSelector((state) => state.job.jobs);
  const updateJobSuccess = useSelector((state) => state.job.updateJobSuccess);

  useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, { dismissible: false });

    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    let filter = {};
    for (let i = 0; i < filters?.length; i++) {
      filter[filters[i].type] = filters[i].value;
    }
    dispatch(
      commonAction(
        {
          url: "/jobs/paginated",
          method: "GET",
          query: { filter },
          loadingType: GET_JOB_LIST_LOADING,
          successType: GET_JOB_LIST_SUCCESS,
          errorType: GET_JOB_LIST_ERROR,
        },
        accessToken,
      ),
    );
  }, []);

  useEffect(() => {
    if (updateJobSuccess) {
      console.log("SUCCESS");

      var elem = document.getElementById("job-modal");
      var instance = M.Modal.getInstance(elem);
      if (instance && instance.isOpen) instance.close();
      dispatch(
        commonAction(
          {
            url: "/jobs/paginated",
            method: "GET",
            loadingType: GET_JOB_LIST_LOADING,
            successType: GET_JOB_LIST_SUCCESS,
            errorType: GET_JOB_LIST_ERROR,
          },
          accessToken,
        ),
      );
      dispatch({ type: JOB_REDUCER_SET, payload: { updateJobSuccess: false } });
    }
  }, [updateJobSuccess]);

  useEffect(() => {
    let filter = {};
    for (let i = 0; i < filters?.length; i++) {
      filter[filters[i].type] = filters[i].value;
    }
    dispatch(
      commonAction(
        {
          url: "/jobs/paginated",
          method: "GET",
          query: { ...filter },
          loadingType: GET_JOB_LIST_LOADING,
          successType: GET_JOB_LIST_SUCCESS,
          errorType: GET_JOB_LIST_ERROR,
        },
        accessToken,
      ),
    );
  }, [filters]);

  const changeFilterType = (e) => {
    setFilterType(e.target.value);
  };

  const changeFilterVal = (val) => {
    setFilterVal(val);
  };

  const getOptions = () => {
    if (filterType === "year") {
      return years;
    } else if (filterType === "quarter") {
      return quarters;
    } else if (filterType === "company") {
      return companies?.map((com) => {
        return { value: com.id, label: com.name };
      });
    }
  };

  const addFilter = () => {
    let temp = [...filters];
    temp.push({ ...filterVal, type: filterType });
    setFilterType("");
    setFilters(temp);
    setFilterVal("");
  };

  const deleteFilter = (i) => {
    let temp = [];
    for (let j = 0; j < filters.length; j++) {
      if (j !== i) {
        temp.push(filters[j]);
      }
    }
    setFilters(temp);
  };

  const onEditRow = (row) => {
    setEditorRow(row);
    setStatus(row.status);
    if (row.info) setInfo(JSON.stringify(row.info));
    var elem = document.getElementById("job-modal");
    var instance = M.Modal.getInstance(elem);
    if (instance && !instance.isOpen) instance.open();
  };

  const submitEdit = (event) => {
    console.log("here");

    event.preventDefault();
    dispatch(
      commonAction(
        {
          url: "/job/" + editRow?.id + "/update",
          method: "PUT",
          data: { status, info: JSON.parse(info) },
          successType: UPDATE_JOB_SUCCESS,
          errorType: UPDATE_JOB_ERROR,
        },
        accessToken,
      ),
    );
  };

  const onRestart = (row) => {
    dispatch(
      commonAction(
        {
          url: "/job/" + row.id + "/reschedule",
          method: "PUT",
          successType: UPDATE_JOB_SUCCESS,
          errorType: UPDATE_JOB_ERROR,
        },
        accessToken,
      ),
    );
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const onChangeInfo = (e) => {
    setInfo(e.target.value);
  };

  const submitCreate = (event, data) => {
    console.log("here");

    event.preventDefault();
    dispatch(
      commonAction(
        {
          url: "/job/create",
          method: "POST",
          data: { ...data, info: JSON.parse(data.info || "{}") },
          successType: UPDATE_JOB_SUCCESS,
          errorType: UPDATE_JOB_ERROR,
        },
        accessToken,
      ),
    );
  };

  const onTableNext = (page, rowsPerPage) => {
    let filter = {};
    for (let i = 0; i < filters?.length; i++) {
      filter[filters[i].type] = filters[i].value;
    }
    dispatch(
      commonAction(
        {
          url: "/jobs/paginated",
          query: { page, rowsPerPage, ...filter },
          method: "GET",
          // loadingType: GET_JOB_LIST_LOADING,
          successType: GET_JOB_LIST_SUCCESS,
          errorType: GET_JOB_LIST_ERROR,
        },
        accessToken,
      ),
    );
  };

  console.log("Job report ", jobs, filters);

  return (
    <div>
      <CreateModal onSave={submitCreate} />
      <EditModal
        row={editRow}
        status={status}
        info={info}
        changeInfo={onChangeInfo}
        changeStatus={onChangeStatus}
        onSave={submitEdit}
      />
      <div className="row" style={{ marginTop: 16 }}>
        <div className="col s4">
          <label>Select Filter</label>
          <select
            id="selectedFilter"
            className="browser-default custom-select"
            onChange={changeFilterType}
            value={filterType}
          >
            <option disabled selected value="">
              Set filter type
            </option>
            <option value="year">Year</option>
            <option value="quarter">Quarter</option>
            <option value="company">Company</option>
            <option value="name">Name</option>
            <option value="status">Status</option>
          </select>
        </div>
        <div className="col s6">
          {filterType ? (
            <>
              {filterType === "year" ||
              filterType === "quarter" ||
              filterType === "company" ? (
                <div className="col s4">
                  <Select
                    value={filterVal}
                    onChange={changeFilterVal}
                    options={getOptions()}
                    placeholder="Select filter value..."
                  />
                </div>
              ) : (
                <div className="col s4">
                  <label>Set value</label>
                  <input
                    value={filterVal?.value}
                    onChange={(event) => {
                      changeFilterVal({
                        value: event.target.value,
                        label: event.target.value,
                      });
                    }}
                  />
                </div>
              )}
              <div className="col s4">
                <IconButton onClick={addFilter} style={{ marginTop: 20 }}>
                  <Icon>add</Icon>
                </IconButton>
              </div>
            </>
          ) : null}
        </div>
        <div className="col s2">
          <button
            data-target="job-create-modal"
            class="btn modal-trigger"
            variant="contained"
          >
            Create job
          </button>
        </div>

        <div className="col s12" style={{ marginTop: 8 }}>
          {filters.map((fil, i) => (
            <Chip
              onDelete={() => {
                deleteFilter(i);
              }}
              label={fil?.label}
            />
          ))}
        </div>
      </div>

      <div className="row">
        <DataTable
          enableToolbar={false}
          selectable={false}
          // loading={validationIssuesLoading}
          data={jobs?.data || []}
          totalCount={jobs?.count || 0}
          minRowInPage={10}
          colFunction={{
            company: (row) => {
              return row?.company?.name;
            },
            nl: (row) => {
              return row?.nl?.lable;
            },
            info: (row) => {
              return row ? JSON.stringify(row.info) : "";
            },
          }}
          columns={[
            "id",
            "name",
            "company",
            "nl",
            "status",
            "info",
            "createdAt",
            "updatedAt",
          ]}
          clipColumns={["info"]}
          rowActions={[
            { icon: "edit", onClick: onEditRow },
            { icon: "refresh", onClick: onRestart },
          ]}
          onPaginateNext={onTableNext}
          // filterOrders={{ nl: nlOrderMap }}
        />
      </div>
    </div>
  );
}
