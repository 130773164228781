import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "../../../utils/nlHelper";
import { useLocation } from "react-router-dom";
import {
  addNLForceMap,
  fetchNLForceMapDetails,
  setReducer,
} from "../../../store/action/nlFixAction";
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import swal from "sweetalert";
import Select from "react-select";

export default function ForceNLMap() {
  const dispatch = useDispatch();
  const location = useLocation();

  const urlQuery = getQuery(location?.search);
  const year = urlQuery?.year;
  const quarter = urlQuery?.quarter;
  const cid = urlQuery?.cid;
  const nlid = urlQuery?.nlid;
  const company = urlQuery?.company;
  const nl = urlQuery?.nl;

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const nlForceMapData = useSelector((state) => state?.nlFix?.nlForceMapData);

  const addForceNlMapLoading = useSelector(
    (state) => state?.nlFix?.addForceNlMapLoading
  );
  const addForceNlMapSuccess = useSelector(
    (state) => state?.nlFix?.addForceNlMapSuccess
  );
  const addForceNlMapError = useSelector(
    (state) => state?.nlFix?.addForceNlMapError
  );
  const addForceNlMapMsg = useSelector(
    (state) => state?.nlFix?.addForceNlMapMsg
  );

  const [map, setMap] = useState([]);

  useEffect(() => {
    dispatch(
      fetchNLForceMapDetails(
        {
          year: year,
          quarter: quarter,
          companyId: cid,
          nlId: nlid,
        },
        accessToken
      )
    );
  }, []);

  useEffect(() => {
    if (addForceNlMapSuccess) {
      swal("Success", addForceNlMapMsg, "success");
      dispatch(setReducer({ addForceNlMapSuccess: false }));
    }
    if (addForceNlMapError) {
      swal("Oops!", addForceNlMapMsg, "error");
      dispatch(setReducer({ addForceNlMapError: false }));
    }
  }, [addForceNlMapSuccess, addForceNlMapError]);

  const assign = (row_id, col_name) => {
    /**
     * Function to assign row_id with col_name
     */

    // Assign map data into a local variable
    const localMap = [...map];

    // Checking if the col_name already exist or not
    const ind = localMap.findIndex((f) => f.col_name === col_name);

    // If row_id already exist then delete it from mapping
    if (ind !== -1) {
      localMap.splice(ind, 1);
    }

    // Add the row_id with col_name on map
    localMap.push({ row_id: row_id, col_name: col_name });

    setMap(localMap);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let final_data = [];
    for (let i = 0; i < map.length; i++) {
      if (map[i].row_id !== "None") {
        final_data.push({
          row_id: map[i].row_id,
          data_records:
            nlForceMapData?.LHS[
              Object.keys(nlForceMapData?.LHS).find(
                (f) => f === map[i].col_name
              )
            ],
        });
      }
    }
    dispatch(addNLForceMap({ map_data: final_data }, accessToken));
  };

  return (
    <>
      {addForceNlMapLoading ? (
        <Alert severity="info">Mapping please wait</Alert>
      ) : (
        <div
          style={{
            position: "relative",
          }}
        >
          <h5
            style={{ textAlign: "center" }}
          >{`Force NL Map (${company} ${year} ${quarter} ${nl})`}</h5>
          <div className="row">
            <div className="col s12">
              {nlForceMapData && (
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: "410px" }}
                >
                  <Table style={{ textAlign: "center" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          Dynamic
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Fixed
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(nlForceMapData?.LHS).map((item, index) => (
                        <TableRow>
                          <TableCell key={item}>{item}</TableCell>
                          <TableCell>
                            <div style={{ width: "780px" }}>
                              <Select
                                value={{
                                  value: map.find((f) => f.col_name === item)
                                    ?.row_id,
                                  label: nlForceMapData?.RHS?.find(
                                    (f) =>
                                      f.id ===
                                      map.find((f) => f.col_name === item)
                                        ?.row_id
                                  )?.name,
                                }}
                                onChange={(option) =>
                                  assign(option?.value, item)
                                }
                                options={nlForceMapData?.RHS?.map((r_item) => {
                                  return {
                                    label: r_item.name,
                                    value: r_item.id,
                                  };
                                })}
                              />
                            </div>
                            {/* <select
                              className="browser-default"
                              value={
                                map.find((f) => f.col_name === item)?.row_id
                              }
                              onChange={(event) =>
                                assign(event.target.value, item)
                              }
                            >
                              <option value={"None"}>None</option>
                              {nlForceMapData?.RHS?.map((r_item) => (
                                <option value={r_item.id}>{r_item.name}</option>
                              ))}
                            </select> */}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow></TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <Button variant="contained" onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
