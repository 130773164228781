import {
  GET_SETTINGS_META_ERROR,
  GET_SETTINGS_META_LOADING,
  GET_SETTINGS_META_SUCCESS,
} from "../constants/authConstants";

const initState = {
  authError: false,
  authLoading: false,
  authErrorMessage: null,

  registerRequestLoading: false,
  registerRequestError: false,
  registerRequestSuccess: false,

  user: null,
  name: "",
  PSExpiry: "",
  NISExpiry: "",
  accessToken: null,
  refreshToken: null,
  rolesAllowed: null,

  configLoading: false,
  configError: false,
  config: null,
  configUpdateLoading: false,
  configUpdateError: false,
  configUpdateSuccess: false,

  resetPasswordLoading: false,
  resetPasswordError: false,
  resetPasswordSuccess: false,

  changePasswordLoading: false,
  changePasswordError: false,
  changePasswordSuccess: false,

  signUpLoading: false,
  signUpSuccess: false,
  signUpError: false,
  signUpMessage: null,
  defaultData: null,

  settingsMeta: [],
  settingsMetaLoading: false,
  settingsMetaSuccess: false,
  settingsMetaError: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "AUTH_LOADING":
      return {
        ...state,
        authLoading: true,
        authError: false,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: true,
        authLoading: false,
        authErrorMessage: action.message,
      };
    case "LOGIN_SUCCESS":
      // //console.log("REDU:", action);
      return {
        ...state,
        authError: null,
        authLoading: false,
        user: action.payload.user,
        name: action.payload.user.name,
        uid: action.payload.user.id,
        isAdmin: action.payload.user.isAdmin,
        rolesAllowed: action.payload.permissions,

        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "TOKEN_REFRESH_SUCCESS":
      // //console.log("REDU:", action.payload.accessToken, action.payload.refreshToken );
      return {
        ...state,
        authError: null,
        authLoading: false,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case "AUTHENTICATION_ERROR":
      //console.log("HERE");
      return initState;
    case "SIGNOUT_SUCCESS":
      //console.log("signout success");
      // window.location.reload();
      return initState;
    case "LOGOUT_ERROR":
      //console.log("signout error");
      return initState;
    case "SIGNUP_ERROR":
      //console.log("sign up error");
      return {
        ...state,
        authError: action.error.message,
      };
    case "SIGNUP_SUCCESS":
      //console.log("sign up success");
      return {
        ...state,
        authError: null,
      };
    case "REQUEST_REGISTER_LOADING":
      return {
        ...state,
        registerRequestLoading: true,
        registerRequestError: false,
        registerRequestSuccess: false,
      };
    case "REQUEST_REGISTER_ERROR":
      return {
        ...state,
        registerRequestLoading: false,
        registerRequestError: true,
        registerRequestSuccess: false,
      };
    case "REQUEST_REGISTER_SUCCESS":
      return {
        ...state,
        registerRequestLoading: false,
        registerRequestError: false,
        registerRequestSuccess: true,
      };
    case "LOADING_USER_CONFIG":
      return {
        ...state,
        configLoading: true,
        configError: false,
      };
    case "USER_CONFIG_LOAD_SUCCESS":
      return {
        ...state,
        configLoading: false,
        configError: false,
        config: action.payload,
      };
    case "USER_CONFIG_LOAD_ERROR":
      return {
        ...state,
        configLoading: false,
        configError: true,
      };
    case "CONFIG_UPDATE_LOADING":
      return {
        ...state,
        configUpdateLoading: true,
        configUpdateError: false,
        configUpdateSuccess: false,
      };
    case "CONFIG_UPDATE_SUCCESS":
      return {
        ...state,
        configLoading: false,
        configUpdateSuccess: true,
        configError: false,
      };
    case "CONFIG_UPDATE_ERROR":
      return {
        ...state,
        configUpdateLoading: false,
        configUpdateError: true,
        configUpdateSuccess: false,
      };

    case "RESET_CONFIG_SUCCESS":
      return {
        ...state,
        configUpdateSuccess: false,
      };
    case "RESET_PASSWORD_LOADING":
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordError: false,
        resetPasswordSuccess: false,
      };
    case "RESET_PASSWORD_SUCCESS":
      alert("Password reset successfuly. Check your mail.");
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: false,
        resetPasswordSuccess: true,
      };
    case "RESET_PASSWORD_ERROR":
      alert("Password reset error...");
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: true,
        resetPasswordSuccess: false,
      };
    case "CHANGE_PASSWORD_LOADING":
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordError: false,
        changePasswordSuccess: false,
      };
    case "CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: false,
        changePasswordSuccess: true,
      };
    case "CHANGE_PASSWORD_ERROR":
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: true,
        changePasswordSuccess: false,
      };
    case "ADD_USER_LOADING":
      return {
        ...state,
        signUpLoading: true,
        signUpError: false,
        signUpSuccess: false,
        signUpMessage: null,
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        signUpLoading: false,
        signUpError: false,
        signUpSuccess: true,
        signUpMessage: action.message,
      };
    case "ADD_USER_ERROR":
      return {
        ...state,
        signUpLoading: false,
        signUpError: true,
        signUpSuccess: false,
        signUpMessage: action.message,
      };
    case GET_SETTINGS_META_LOADING:
      return {
        ...state,
        settingsMetaLoading: true,
        settingsMetaError: false,
        settingsMetaSuccess: false,
        settingsMeta: [],
      };
    case GET_SETTINGS_META_SUCCESS:
      return {
        ...state,
        settingsMetaLoading: false,
        settingsMetaError: false,
        settingsMetaSuccess: true,
        settingsMeta: action.payload,
      };
    case GET_SETTINGS_META_ERROR:
      return {
        ...state,
        settingsMetaLoading: false,
        settingsMetaError: true,
        settingsMetaSuccess: false,
        settingsMeta: [],
      };
    case "SAVE_DEFAULT":
      return {
        ...state,
        defaultData: action.data,
      };
    default:
      return state;
  }
};
export default authReducer;
