export const GET_JOB_LIST_LOADING = "GET_JOB_LIST_LOADING";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_ERROR = "GET_JOB_LIST_ERROR";

export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const UPDATE_JOB_LOADING = "UPDATE_JOB_LOADING";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_ERROR = "UPDATE_JOB_ERROR";
