import config from "../../config/config";
import { queryBuilder } from "../../utils/helper";

var backendUrl = config?.backendUrl;

export const commonAction = (body, token) => {
  const { url, method, data, errorType, loadingType, successType, query } =
    body;
  return (dispatch) => {
    var fullUrl = queryBuilder(backendUrl + url, query);
    if (loadingType)
      dispatch({
        type: loadingType,
      });
    // dispatch({
    //   type: loadingType,
    // });
    console.log("data", data);

    fetch(fullUrl, {
      method,
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            if (successType)
              dispatch({
                type: successType,
                payload: data?.data,
                messsage: data?.messsage,
              });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            if (errorType)
              dispatch({
                type: errorType,
                payload: data,
                messsage: data?.messsage || data.msg || "Internal error",
              });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: errorType,
        });
      });
  };
};
