import React from "react";
import BarStackCombination from "./commonDashboards/BarStackCombination";

export default function RevenuePerf({ label, data }) {
  console.log("REPORT DATA", data);

  const BAR_CHART_HEIGHT = 255;
  const BAR_CHART_WIDTH = 350;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "gdp",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "gdp" }],
        label: "Gross Direct Premium",
        data: data?.gdp || [],
      }}
      barTwoDetails={{
        id: "pat",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "value" }],
        label: "Profit After Tax",
        data: data?.pat || [],
      }}
      stackBarDetails={{
        id: "seg-rev",
        height: 220,
        width: 800,
        dimensions: [{ text: "company" }],
        measures: [{ text: "percentage" }],
        group: "segment_x",
        label: "Contribution of products",
        data: data ? data["gdp-seg"] : [],
        gic: false,
      }}
      lineChartDetails={{
        id: "seg-trend",
        height: BAR_CHART_HEIGHT,
        width: 800,
        dimensions: [{ text: "fyq" }],
        measures: [{ text: "value" }],
        group: "company",
        label: "GDP Trend",
        data: data
          ? data["gdp-trend"]?.map((row) => {
              return { ...row, fyq: row.year + ", " + row.quarter };
            })
          : [],
        gic: false,
      }}
    />
  );
}
