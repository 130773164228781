import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import {
  getNLReportsList,
  getNLReportsData,
} from "../../store/action/nlActions";
import { useState } from "react";
import GeoDashboard from "./nlReports/GeoDashboard";
import AgingDashboard from "./nlReports/AgingDashboard";
import LoaderFull from "../layout/LoaderFull";
import IndustryPerf from "./nlReports/IndustryPerf";
import SegmentsPerf from "./nlReports/SegmentsPerf";
import RevenuePerf from "./nlReports/RevenuePerf";
import SegmentClaims from "./nlReports/SegmentClaims";
import SegmentCommission from "./nlReports/SegmentCommission";
import OpexComparision from "./nlReports/OpexComparision";
import MarketShare from "./nlReports/MarketShare";
import LobPerf from "./nlReports/LobPerf";
import useQuery from "./nlReports/laylout/useQuery";
import TrendDashboard from "./nlReports/TrendDashboard";
import GeoCompDashboard from "./nlReports/GeoCompDashboard";
import PeerDashboard from "./nlReports/PeerDashboard";
import PortfolioMixDashboard from "./nlReports/PortfolioMixDashboard";
import {
  ENTITY_STATUS,
  NL_TYPE_GIC,
  NL_TYPE_LIFE,
} from "../../config/constants";
import RevenuePerfLife from "./nlReports/RevenuePerfLife";
import GeoDashboardLife from "./nlReports/GeoDashboardLife";
import GeoCompDashboardLife from "./nlReports/GeoCompDashboardLife";
import SegmentClaimsLife from "./nlReports/SegmentClaimsLife";
import SegmentCommissionLife from "./nlReports/SegmentCommissionLife";
import OpexComparisionLife from "./nlReports/OpexComparisionLife";
import GoalDashboard from "./nlReports/GoalDashboard";

export default function NLReportDashboard() {
  const query = useQuery();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const nlReports = useSelector((state) => state.nl.nlReports);
  const nlReportData = useSelector((state) => state.nl.nlReportData);
  const yearQuarters = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const nlReportDataLoading = useSelector(
    (state) => state.nl.nlReportDataLoading,
  );

  const [report, setReport] = useState(null);
  const [type, setType] = useState(null);
  const [yearQuarter, setYq] = useState(null);

  useEffect(() => {
    console.log("LOCATION: ", location);
    setType(query.get("type"));
    if (location?.pathname?.includes("/life"))
      dispatch(getNLReportsList({ com_type: "life" }, auth.accessToken));
    else dispatch(getNLReportsList({ com_type: "gic" }, auth.accessToken));
  }, []);

  useEffect(() => {
    if (nlReports?.length > 0 && type === "monthly") {
      let tem_report = nlReports.find(
        (report) => report.name === "peer-dashboard",
      );
      onReportClick(tem_report);
    } else if (
      nlReports?.length > 0 &&
      !location?.pathname?.includes("/life") &&
      yearQuarter
    ) {
      let tem_report = nlReports.find(
        (report) => report.name === "goal-dashboard",
      );
      onReportClick(tem_report);
    }
  }, [nlReports]);

  useEffect(() => {
    if (yearQuarters?.length > 0) {
      let yqsActive = yearQuarters.filter(
        (yq) => yq.status === ENTITY_STATUS.ACTIVE,
      );
      let yq = null;

      if (location?.pathname?.includes("/life")) {
        let temp = yqsActive.filter((item) => item.type === "gic");
        yq = temp[temp.length - 1];
      } else {
        let temp = yqsActive.filter((item) => item.type === "gic");
        yq = temp[temp.length - 1];
      }
      setYq(yq);
    }
  }, [yearQuarters]);

  useEffect(() => {
    if (yearQuarter && report) onReportClick(report);
  }, [yearQuarter]);

  const onReportClick = (report) => {
    setReport(report);
    if (!report.multiApi) {
      if (location?.pathname?.includes("/life"))
        dispatch(
          getNLReportsData(
            report.name,
            {
              com_type: "life",
              year: yearQuarter?.fyq?.split("-")[0],
              quarter: yearQuarter?.fyq?.split("-")[1],
            },
            auth.accessToken,
          ),
        );
      else
        dispatch(
          getNLReportsData(
            report.name,
            {
              com_type: "gic",
              year: yearQuarter?.fyq?.split("-")[0],
              quarter: yearQuarter?.fyq?.split("-")[1],
            },
            auth.accessToken,
          ),
        );
    }
  };

  const changeQuarter = (direction) => {
    let spot = yearQuarters?.findIndex((yq) => yq.id === yearQuarter.id);
    if (direction === "prev") {
      if (spot > 0) {
        let yq = yearQuarters[spot - 1];
        setYq(yq);
      }
    } else if (direction === "next") {
      if (spot < yearQuarters.length - 1) {
        let yq = yearQuarters[spot + 1];
        setYq(yq);
      }
    }
  };

  console.log("REPORTs", nlReports, yearQuarters);

  if (!auth.uid) return <Redirect to="/SignIn" />;
  return (
    <div className="row" style={{ marginTop: 8 }}>
      {nlReportDataLoading && <LoaderFull />}
      <div className="col s12 l2 m5">
        <div
          className="row fixed-side-bar nl-report-side-bar"
          style={{ backgroundColor: "#454241" }}
        >
          {nlReports
            ?.filter((report) => report.type === type)
            ?.map((nlReport) => (
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={
                  report?.id === nlReport.id
                    ? "report-select active-side"
                    : "report-select"
                }
                onClick={() => {
                  onReportClick(nlReport);
                }}
              >
                {nlReport.label}
                {report?.id === nlReport.id && (
                  <i
                    style={{
                      transform: "rotate(90deg)",
                      marginRight: -20,
                      fontSize: 32,
                    }}
                    className="material-icons white-text"
                  >
                    arrow_drop_down
                  </i>
                )}
              </p>
            ))}
        </div>
      </div>
      <div className="col s12 l10 m7">
        {report && (
          <strong>
            <p className="center center-align report-headline">
              {report.label}{" "}
              {yearQuarter
                ? report?.name === "market-share"
                  ? " | as of : " + yearQuarter?.label
                  : " | " + yearQuarter?.label
                : ""}
              {yearQuarters?.findIndex((yq) => yq.id === yearQuarter.id) >
                0 && (
                <i
                  className="material-icons "
                  style={{
                    marginLeft: 16,
                    color: "#f25d18",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    changeQuarter("prev");
                  }}
                >
                  chevron_left
                </i>
              )}
              {yearQuarters?.findIndex((yq) => yq.id === yearQuarter.id) <
                yearQuarters?.length - 1 && (
                <i
                  className="material-icons"
                  style={{ color: "#f25d18", cursor: "pointer" }}
                  onClick={() => {
                    changeQuarter("next");
                  }}
                >
                  chevron_right
                </i>
              )}
            </p>
          </strong>
        )}

        {nlReportData || report?.multiApi ? (
          report?.name === "market-share" ? (
            <MarketShare data={nlReportData?.data} />
          ) : report?.name === "industry-perf" ? (
            <IndustryPerf data={nlReportData} />
          ) : report?.name === "seg-perf" ? (
            <SegmentsPerf data={nlReportData?.data} />
          ) : report?.name === "geo-dashboard" ? (
            <GeoDashboard data={nlReportData} />
          ) : report?.name === "geo-comp-dashboard" ? (
            <GeoCompDashboard data={nlReportData} />
          ) : report?.name === "rev-perf" ? (
            <RevenuePerf data={nlReportData} />
          ) : report?.name === "seg-claim" ? (
            <SegmentClaims data={nlReportData} />
          ) : report?.name === "seg-comm" ? (
            <SegmentCommission data={nlReportData} />
          ) : report?.name === "opex-com" ? (
            <OpexComparision data={nlReportData} />
          ) : report?.name === "lob-perf" ? (
            <LobPerf data={nlReportData} />
          ) : report?.name === "aging-dashboard" ? (
            <AgingDashboard data={nlReportData} />
          ) : report?.name === "trend-dashboard" ? (
            <TrendDashboard data={nlReportData} />
          ) : report?.name === "peer-dashboard" ? (
            <PeerDashboard />
          ) : report?.name === "portfolio-mix" ? (
            <PortfolioMixDashboard />
          ) : report?.name === "goal-dashboard" ? (
            <GoalDashboard data={nlReportData} fyq={yearQuarter?.fyq} />
          ) : report?.name === "rev-perf-life" ? (
            <RevenuePerfLife data={nlReportData?.data} />
          ) : report?.name === "seg-claim-life" ? (
            <SegmentClaimsLife data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "opex-com-life" ? (
            <OpexComparisionLife
              data={nlReportData?.data}
              type={NL_TYPE_LIFE}
            />
          ) : report?.name === "seg-comm-life" ? (
            <SegmentCommissionLife
              data={nlReportData?.data}
              type={NL_TYPE_LIFE}
            />
          ) : report?.name === "geo-dashboard-life" ? (
            <GeoDashboardLife data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "geo-comp-dashboard-life" ? (
            <GeoCompDashboardLife
              data={nlReportData?.data}
              type={NL_TYPE_LIFE}
            />
          ) : null
        ) : null}
      </div>
    </div>
  );
}
