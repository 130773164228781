import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Segments from "./laylout/Segments";
import { orderFilters } from "../../../utils/tableHelper";
import BarPieCombinationGic from "./commonDashboards/BarPieCombinationGic";

export default function OpexComparision({ label, data, type }) {
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState([]);

  useEffect(() => {
    if (data && data["opex-total"]) {
      let temp_lobs = [];
      for (let i = 0; i < data["opex-total"]?.length; i++) {
        if (!temp_lobs.includes(data["opex-total"][i]["segment"])) {
          temp_lobs.push(data["opex-total"][i]["segment"]);
        }
      }
      temp_lobs = orderFilters(temp_lobs);
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);
    }
  }, [data]);

  const handleLobChange = (lob) => {
    setSelLob(lob);
  };

  // console.log("LOBS", lobs, data["opex-total"], data["opex-seg"]);

  const BAR_CHART_HEIGHT = 230;
  const BAR_CHART_WIDTH = 450;

  return (
    <div className="row">
      <Segments lobs={lobs} selLob={selLob} handleLobChange={handleLobChange} />
      <BarPieCombinationGic
        barDetails={{
          label: "Operating Expences",
          data: data["opex-total"]?.filter((row) => row.segment === selLob),
          height: BAR_CHART_HEIGHT,
          width: BAR_CHART_WIDTH,
          dimensions: [{ text: "company" }],
          measures: [{ text: "value" }],
        }}
        pieDetails={{
          data: data["opex-seg"]?.filter((row) => row.segment === selLob),
          label: "OPEX distribution",
          dimensions: [{ text: "particular_x" }],
          measures: [{ text: "percentage" }],
        }}
        lineChartDetails={{
          id: "opex-trend",
          height: 240,
          width: 800,
          dimensions: [{ text: "fyq" }],
          measures: [{ text: "value" }],
          group: "company",
          label: "Operating expense trend",
          data: data
            ? data["opex-trend"]
                ?.map((row) => {
                  return { ...row, fyq: row.year + ", " + row.quarter };
                })
                .filter(
                  (row) => row.segment === selLob && row.fyq !== "21_22, q4",
                  // &&
                  // row.company === "TATA AIG"
                )
            : [],
          gic: false,
        }}
        lengendMaxChar={15}
      />
      {/* <div className="col s12">
        <p className="notes-text">
          ** All cost figures are in percentage calculated by ( cost in
          department/ total cost) * 100 for the segment
        </p>
      </div> */}
    </div>
  );
}
