import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { orderFilters } from "../../../utils/tableHelper";
import BarChartGeneric from "../../layout/BarChartGeneric";
import LineChartGeneric from "../../layout/LineChartGeneric";
import * as d3 from "d3";

export default function AgingDashboard({ label, data }) {
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState([]);

  useEffect(() => {
    if (data) {
      let temp_lobs = [];
      for (let i = 0; i < data["aging-nop-total"]?.length; i++) {
        if (!temp_lobs.includes(data["aging-nop-total"][i]["particular"])) {
          temp_lobs.push(data["aging-nop-total"][i]["particular"]);
        }
      }
      temp_lobs = orderFilters(temp_lobs);
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);
    }
  }, [data]);

  const makeTimeWise = (arrayData, coms) => {
    let newData = [];
    let segKeys = Object.keys(arrayData[0])?.filter((key) => key.includes("%"));
    for (let i = 0; i < segKeys.length; i++) {
      // if (segKeys[i] === "<1M (%)" || segKeys[i] === ">1M & <=3M (%)") {
      //   continue;
      // }
      let key = segKeys[i].replaceAll("(%)", "").trim();
      key = key.replaceAll("<", "").trim();
      key = key.replaceAll(">", "").trim();
      key = key.replaceAll("=", "").trim();
      // key = key.replaceAll("M", "").trim();
      // key = key.replaceAll("Y", "").trim();
      key = key.replaceAll("&", "-").trim();
      let newOb = { key, date: key };
      for (let j = 0; j < coms.length; j++) {
        let comData = arrayData.find((d) => d.Company === coms[j]);
        newOb[coms[j]] = comData[segKeys[i]];
      }
      newData.push(newOb);
    }
    console.log("NEW DATA", newData);
    return newData;
  };

  const handleLobChange = (lob) => {
    setSelLob(lob);
  };

  // console.log("COLS", cols);
  console.log("LOBS", lobs);
  console.log("REPORT DATA", data);

  const BAR_CHART_HEIGHT = 220;
  const BAR_CHART_WIDTH = 350;
  const LINE_CHART_HEIGHT = 250;
  const LINE_CHART_WIDTH = 780;

  return (
    <>
      <div className="row" style={{ marginBottom: 0 }}>
        <div className="col s12">
          <div className="stat-row">
            {lobs?.map((lob) => (
              <div
                className="btn btn-small"
                onClick={() => {
                  handleLobChange(lob);
                }}
                style={
                  lob === selLob
                    ? {
                        marginRight: 8,
                        marginBottom: 8,
                        backgroundColor: "#124265",
                      }
                    : {
                        marginRight: 8,
                        marginBottom: 8,
                        backgroundColor: "#dcdce2",
                        color: "black",
                      }
                }
              >
                {lob}
              </div>
            ))}
          </div>
          {/* <div className="col s2">
            <label>
              <input
                name="group1"
                type="radio"
                checked={type === "nop"}
                onChange={() => {
                  handleTypeChange("nop");
                }}
              />
              <span>NOP</span>
            </label>
            <label>
              <input
                name="group1"
                type="radio"
                checked={type === "amount"}
                onChange={() => {
                  handleTypeChange("amount");
                }}
              />
              <span>Premium</span>
            </label>
          </div> */}
        </div>
        <div className="col s12">
          <div className="col s4">
            <div>
              <div style={{ fontSize: 18 }}>
                <strong>Policy Wise</strong>
              </div>
              <BarChartGeneric
                id={"nop-overall"}
                data={data["aging-nop-total"]?.filter(
                  (row) => row.particular === selLob,
                )}
                dimensions={[{ text: "company" }]}
                measures={[{ text: "value" }]}
                height={BAR_CHART_HEIGHT}
                width={BAR_CHART_WIDTH}
                hideLegend={false}
                margins={{ left: 100, bottom: 10 }}
                fontSize={12}
                boldFont={true}
                noLabelWrapY={true}
                noXLabel={true}
                labelInBar={false}
                barFontSize={10}
              />
            </div>
            <div>
              <div style={{ fontSize: 18 }}>
                <strong>Premium Wise</strong>
              </div>
              <BarChartGeneric
                id={"amount-overall"}
                data={data["aging-premium-total"]?.filter(
                  (row) => row.particular === selLob,
                )}
                dimensions={[{ text: "company" }]}
                measures={[{ text: "value" }]}
                height={BAR_CHART_HEIGHT}
                width={BAR_CHART_WIDTH}
                hideLegend={false}
                margins={{ left: 100, bottom: 10 }}
                fontSize={12}
                boldFont={true}
                noLabelWrapY={true}
                noXLabel={true}
                labelInBar={false}
                barFontSize={10}
              />
            </div>
            {/* <p className="notes-text">
              ** All segment wise figures are in percentage of total for the
              segment
            </p> */}
          </div>
          <div className="col s8">
            <div>
              <LineChartGeneric
                id="trend-nop"
                data={data["aging-nop"]?.filter(
                  (row) => row.particular === selLob,
                )}
                group="company"
                dimensions={[{ text: "segment" }]}
                measures={[{ text: "value" }]}
                can_width={LINE_CHART_WIDTH}
                can_height={LINE_CHART_HEIGHT}
                tooltip={false}
                labelInBar={true}
                margins={{ bottom: 20, left: 50, right: 10 }}
                legendOrientation={"top"}
                rotateXTick={true}
              />
            </div>
            <div>
              <LineChartGeneric
                id="trend-amount"
                data={data["aging-premium"]?.filter(
                  (row) => row.particular === selLob,
                )}
                group="company"
                dimensions={[{ text: "segment" }]}
                measures={[{ text: "value" }]}
                can_width={LINE_CHART_WIDTH}
                can_height={LINE_CHART_HEIGHT}
                tooltip={false}
                labelInBar={true}
                margins={{ bottom: 20, left: 50, right: 10 }}
                legendOrientation={"top"}
                rotateXTick={true}
              />
            </div>
            <div>
              {/* {allComs?.map((com, i) => (
                <div
                  className="col s2"
                  title={com}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 12,
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      minHeight: 10,
                      minWidth: 10,
                      height: 10,
                      width: 10,
                      marginRight: 10,
                      backgroundColor: d3.schemeCategory10[i],
                    }}
                  ></div>
                  <div>{com.length > 15 ? com.slice(0, 15) + "..." : com}</div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
