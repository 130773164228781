import config from "../../config/config";
import { queryBuilder } from "../../utils/helper";
import {
  GET_SETTINGS_META_ERROR,
  GET_SETTINGS_META_LOADING,
  GET_SETTINGS_META_SUCCESS,
} from "../constants/authConstants";

var backendUrl = config.backendUrl;

export const signIn = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: "AUTH_LOADING",
    });
    fetch(backendUrl + "/login", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((response) =>
        response
          .json()
          .then((data) => {
            // //console.log(data);
            if (response.status === 200) {
              dispatch({
                type: "LOGIN_SUCCESS",
                payload: data,
              });
            } else if (response.status === 403 || response.status === 401) {
              dispatch({
                type: "LOGIN_ERROR",
                message: data.message,
              });
            } else {
              dispatch({
                type: "LOGIN_ERROR",
                message: data.message,
              });
            }
          })
          .catch((err) => {
            console.error("Error in login", err);
            dispatch({
              type: "LOGIN_ERROR",
              message: "Internal Error",
            });
          }),
      )
      .catch((err) => {
        console.error("Error in login", err);
        dispatch({
          type: "LOGIN_ERROR",
          message: "Internal Error",
        });
      });
  };
};

export const signOut = (token) => {
  return (dispatch) => {
    dispatch({ type: "AUTH_LOADING" });
    fetch(backendUrl + "/logout", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            dispatch({
              type: "SIGNOUT_SUCCESS",
            });
          } else {
            dispatch({
              type: "LOGOUT_ERROR",
            });
          }
        }),
      )
      .catch((err) => {
        dispatch({
          type: "LOGOUT_ERROR",
        });
      });
  };
};

export const refreshToken = (token, refreshToken) => {
  return (dispatch) => {
    dispatch({ type: "TOKEN_REFRESHING" });
    fetch(backendUrl + "/refreshtoken", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        refreshToken: refreshToken,
      }),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status == 200) {
            // //console.log("refreshed token", data.accessToken);
            dispatch({
              type: "TOKEN_REFRESH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "TOKEN_REFRESH_ERROR",
            });
          }
        }),
      )
      .catch((err) => {
        dispatch({
          type: "TOKEN_REFRESH_ERROR",
        });
      });
  };
};

export const registerRequest = (credentials) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REGISTER_LOADING",
    });
    fetch(backendUrl + "/request", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          // //console.log(data);
          if (response.status === 200) {
            dispatch({
              type: "REQUEST_REGISTER_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "REQUEST_REGISTER_ERROR",
            });
          } else {
            dispatch({
              type: "REQUEST_REGISTER_ERROR",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "REQUEST_REGISTER_ERROR",
          });
        }),
    );
  };
};

export const getConfig = (token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_USER_CONFIG" });
    fetch(backendUrl + "/userconfig", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          if (response.status == 200) {
            //console.log("CONFIG", data);
            dispatch({
              type: "USER_CONFIG_LOAD_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "USER_CONFIG_LOAD_ERROR",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "USER_CONFIG_LOAD_ERROR",
        });
      });
  };
};

export const updateConfig = (config, token) => {
  return (dispatch) => {
    dispatch({
      type: "CONFIG_UPDATE_LOADING",
    });
    fetch(backendUrl + "/userconfig", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(config),
    })
      .then((response) =>
        response
          .json()
          .then((data) => {
            // //console.log(data);
            if (response.status === 200) {
              dispatch({
                type: "CONFIG_UPDATE_SUCCESS",
                payload: data,
              });
            } else if (response.status === 403 || response.status === 401) {
              dispatch({
                type: "AUTHENTICATION_ERROR",
              });
            } else {
              dispatch({
                type: "CONFIG_UPDATE_ERROR",
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: "CONFIG_UPDATE_ERROR",
            });
          }),
      )
      .catch((err) => {
        dispatch({
          type: "CONFIG_UPDATE_ERROR",
        });
      });
  };
};

export const signUp = (newUser, token) => {
  return (dispatch) => {
    dispatch({
      type: "ADD_USER_LOADING",
    });
    fetch(backendUrl + "/signup", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newUser),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch({
              type: "ADD_USER_SUCCESS",
              payload: data,
              message: data.message,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
              message: data.error,
            });
          } else {
            dispatch({
              type: "ADD_USER_ERROR",
              message: data.error,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "ADD_USER_ERROR",
          });
        }),
    );
  };
};

export const configUpdateReset = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_CONFIG_SUCCESS" });
  };
};

export const resetPass = (email) => {
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_LOADING",
    });
    fetch(backendUrl + "/resetPass", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          // //console.log(data);
          if (response.status === 200) {
            dispatch({
              type: "RESET_PASSWORD_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "RESET_PASSWORD_ERROR",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "RESET_PASSWORD_ERROR",
          });
        }),
    );
  };
};

export const changePassword = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: "CHANGE_PASSWORD_LOADING",
    });
    fetch(backendUrl + "/changePass", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: data.email,
        oldPass: data.password,
        newPass: data.newPassword,
      }),
    }).then((response) =>
      response
        .json()
        .then((data) => {
          // //console.log(data);
          if (response.status === 200) {
            dispatch({
              type: "CHANGE_PASSWORD_SUCCESS",
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "CHANGE_PASSWORD_ERROR",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "CHANGE_PASSWORD_ERROR",
          });
        }),
    );
  };
};

export const getSettingsMeta = (query, token) => {
  return (dispatch) => {
    dispatch({ type: GET_SETTINGS_META_LOADING });
    fetch(queryBuilder(backendUrl + "/settings/meta", query), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          if (response.status == 200) {
            //console.log("CONFIG", data);
            dispatch({
              type: GET_SETTINGS_META_SUCCESS,
              payload: data.data,
            });
          } else {
            dispatch({
              type: GET_SETTINGS_META_ERROR,
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTINGS_META_ERROR,
        });
      });
  };
};
