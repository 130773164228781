import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function CreateModal({ onSave }) {
  const fields = [
    { id: "name", label: "Name" },
    { id: "year", label: "Year" },
    { id: "quarter", label: "Quarter" },
    { id: "info", label: "Info", multiLine: true },
  ];
  const [values, setValues] = useState({});

  const handleChange = (e) => {
    let temp = { ...values };
    temp[e.target.id] = e.target.value;
    setValues(temp);
  };

  return (
    <div>
      <div id="job-create-modal" className="modal modal-fixed-footer">
        <div className="row modal-content">
          <div className="row modal-title">
            <h5 style={{ color: "rgb(93, 27, 143)" }}>Create Job</h5>
          </div>
          {fields?.map((field) => (
            <div className="col s4">
              <label>{field?.label}</label>
              <input
                id={field.id}
                fullWidth={true}
                multiline={field?.multiLine?.toString()}
                style={field?.multiLine ? { height: 150 } : {}}
                value={values[field.id] || ""}
                onChange={handleChange}
                minRows={field?.multiLine ? 10 : 2}
                label={field?.label}
                variant="standard"
              />
            </div>
          ))}
        </div>

        <div className="modal-footer">
          <button className="modal-close waves-effect waves btn-flat">
            CANCEL
          </button>
          <button
            type="submit"
            form="config"
            className="waves-effect waves btn-flat"
            onClick={(e) => {
              onSave(e, values);
            }}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}
