import {
  COMMON_LOADING_RESET,
  JOB_REDUCER_SET,
} from "../constants/commonConstants";
import {
  AUTHENTICATION_ERROR,
  GET_JOB_LIST_ERROR,
  GET_JOB_LIST_LOADING,
  GET_JOB_LIST_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_JOB_ERROR,
  UPDATE_JOB_LOADING,
  UPDATE_JOB_SUCCESS,
} from "../constants/jobConstants";

const initState = {
  fetchJobLoading: false,
  fetchJobSuccess: false,
  fetchJobError: false,
  jobs: null,
  updateJobLoading: false,
  updateJobSuccess: false,
  updateJobError: false,
  visited: [],
};

const jobReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_JOB_LIST_LOADING:
      return {
        ...state,
        fetchJobLoading: true,
        fetchJobSuccess: false,
        fetchJobError: false,
        visited: [],
        jobs: null,
      };
    case GET_JOB_LIST_SUCCESS:
      // console.log(state?.jobs?.data, action?.payload?.data);
      let tempvisited = [...state.visited];
      let flag = false;
      if (!state.visited.includes(action.payload.page)) {
        tempvisited.push(action.payload.page);
        flag = true;
      }
      return {
        ...state,
        fetchJobLoading: false,
        fetchJobSuccess: true,
        fetchJobError: false,
        visited: tempvisited,
        jobs: flag
          ? {
              ...(action.payload || {}),
              data: [
                ...(state?.jobs?.data || []),
                ...(action?.payload?.data || []),
              ],
            }
          : state.jobs,
      };
    case GET_JOB_LIST_ERROR:
      return {
        ...state,
        fetchJobLoading: false,
        fetchJobSuccess: false,
        fetchJobError: true,
        jobs: null,
      };
    case UPDATE_JOB_LOADING:
      return {
        ...state,
        updateJobLoading: true,
        updateJobSuccess: false,
        updateJobError: false,
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        updateJobLoading: false,
        updateJobSuccess: true,
        updateJobError: false,
      };
    case UPDATE_JOB_ERROR:
      return {
        ...state,
        updateJobLoading: false,
        updateJobSuccess: false,
        updateJobError: true,
      };
    case JOB_REDUCER_SET:
      return {
        ...state,
        ...(action.payload || {}),
      };
    case COMMON_LOADING_RESET:
    case AUTHENTICATION_ERROR:
    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};
export default jobReducer;
