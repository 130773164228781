import { Button, Icon } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import Select from "react-select";
import { queryBuilder } from "../../../utils/helper";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";

export default function QueryHead({
  changeYear,
  years,
  year,
  changeQuarter,
  quarters,
  quarter,
  company,
  changeCompany,
  companies,
  changeNL,
  nl,
  nlList,
  getIssues,
  isInsuranceCompany,
  isLifeCompany,
  getPdf,
  runValidation,
  generateExcel,
  nlPdfLoading,
  changeCompanies,
  selectedCompanies,
  selectedTemplate,
  nlTemplates,
  changeTemp,
  selectedInsuranceType,
  setSelectedInsuranceType,
}) {
  return (
    <div className="row dense-row">
      <div
        id="generate-excel"
        className="modal dashboard-modal modal-fixed-footer"
      >
        <div className="modal-content">
          <h4 className="modal-title">Generate New Excel</h4>
          <label>Select Type </label>
          <select
            value={selectedInsuranceType}
            className="browser-default custom-select"
            onChange={(e) => {
              setSelectedInsuranceType(e.target.value);
            }}
          >
            <option value={NL_TYPE_GIC}>General</option>
            <option value={NL_TYPE_LIFE}>Life</option>
          </select>
          <label>Select companies </label>
          <Select
            value={selectedCompanies}
            onChange={changeCompanies}
            options={companies
              ?.filter(
                selectedInsuranceType === NL_TYPE_GIC
                  ? isInsuranceCompany
                  : isLifeCompany
              )
              ?.map((com) => {
                return { value: com.id, label: com.name };
              })}
            placeholder="Select companies..."
            isMulti={true}
            closeMenuOnSelect={false}
          />
          <label>Select template</label>
          <Select
            value={selectedTemplate}
            onChange={changeTemp}
            options={nlTemplates?.map((temp) => {
              return { value: temp.id, label: temp.label };
            })}
            placeholder="Select template"
          />
          <p>Select both the dropdowns to proceed</p>
        </div>
        <div class="modal-footer">
          <button
            onClick={generateExcel}
            disabled={
              !selectedTemplate ||
              !selectedCompanies ||
              selectedCompanies.length < 1
            }
            className="btn modal-close waves-effect waves-green btn-flat"
          >
            Submit
          </button>
        </div>
      </div>
      <div className="col s6">
        <div className="row">
          <div className="col s4">
            <label>Select Year</label>
            <select
              className="browser-default  custom-select"
              onChange={changeYear}
              value={year}
            >
              <option value="">Set Year</option>
              {years?.map((year) => (
                <option value={year.value}>{year.label}</option>
              ))}
            </select>
          </div>
          <div className="col s4">
            <label>Select Quarter</label>
            <select
              id="selectedQuarter"
              className="browser-default custom-select"
              onChange={changeQuarter}
              value={quarter}
            >
              <option value="">Set quarter</option>
              {quarters?.map((quarter) => (
                <option value={quarter.value}>{quarter.label}</option>
              ))}
            </select>
          </div>
          <div className="col s4">
            <label>Select Template</label>
            <select
              id="selectedTemplate"
              className="browser-default custom-select"
              onChange={(e) => {
                console.log("change");

                changeTemp({
                  value: Number(e.target.value),
                  label: nlTemplates?.find(
                    (tem) => tem.id === Number(e.target.value)
                  )?.label,
                });
              }}
              value={selectedTemplate?.value || ""}
            >
              <option value="">Set template</option>
              {nlTemplates?.map((template) => (
                <option value={template.id}>{template.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <Button
            variant="contained"
            onClick={getIssues}
            disabled={year == "" || quarter == ""}
          >
            Get Issues
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            variant="contained"
            onClick={runValidation}
            disabled={year == "" || quarter == ""}
          >
            Validate
          </Button>
          <Button
            data-target="generate-excel"
            className="modal-trigger"
            disabled={year === "" || quarter === ""}
            style={{ marginLeft: 10 }}
            variant="contained"
          >
            Generate Excel
          </Button>
        </div>
      </div>

      <div className="col s6">
        <div className="row">
          <div className="col s4">
            <label>Company Type</label>
            <select
              value={selectedInsuranceType}
              className="browser-default custom-select"
              onChange={(e) => {
                setSelectedInsuranceType(e.target.value);
              }}
            >
              <option value={NL_TYPE_GIC}>General</option>
              <option value={NL_TYPE_LIFE}>Life</option>
            </select>
          </div>
          <div className="col s4">
            <label>Company</label>
            <select
              className="browser-default custom-select "
              onChange={changeCompany}
              value={company}
            >
              <option value="">Set Company</option>
              {companies
                ?.filter(
                  selectedInsuranceType === NL_TYPE_GIC
                    ? isInsuranceCompany
                    : isLifeCompany
                )
                ?.map((company) => (
                  <option value={company.id}>{company.name}</option>
                ))}
            </select>
          </div>
          <div className="col s4">
            <label>Select NL</label>
            <select
              className="browser-default custom-select"
              onChange={changeNL}
              value={nl}
            >
              <option value="">Set NL</option>
              {nlList?.map((nl) => (
                <option value={nl.id}>{nl.lable}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <Button
            disabled={
              year === "" ||
              quarter === "" ||
              nl === "" ||
              company === "" ||
              nlPdfLoading
            }
            variant="contained"
            onClick={getPdf}
          >
            Get PDF
          </Button>
          <Link
            to={{
              pathname: queryBuilder("/nl/structure/fix", {
                company: companies?.find(
                  (com) => Number(com.id) === Number(company)
                )?.name,
                nl: nlList?.find((n) => Number(n.id) === Number(nl))?.lable,
                cid: company,
                nlid: nl,
              }),
            }}
            target="_blank"
            rel="noopener noreferrer"
            disabled={nl === ""}
            // className='right'
          >
            <Button
              disabled={nl === ""}
              style={{ marginLeft: 10 }}
              variant="contained"
              // onClick={getPdf}
            >
              Fix Structure
              <Icon>open_in_new</Icon>
            </Button>
          </Link>
          <Link
            to={{
              pathname: queryBuilder("/nl/data/fix", {
                company: companies?.find(
                  (com) => Number(com.id) === Number(company)
                )?.name,
                nl: nlList?.find((n) => Number(n.id) === Number(nl))?.lable,
                year,
                quarter,
                cid: company,
                nlid: nl,
              }),
            }}
            target="_blank"
            rel="noopener noreferrer"
            disabled={
              year === "" || quarter === "" || nl === "" || company === ""
            }
            // className="right"
          >
            <Button
              disabled={
                year === "" || quarter === "" || nl === "" || company === ""
              }
              style={{ marginLeft: 10 }}
              variant="contained"
            >
              Fix Data
              <Icon>open_in_new</Icon>
            </Button>
          </Link>
          <Link
            to={{
              pathname: queryBuilder("/nl/data/raw/fix", {
                company: companies?.find(
                  (com) => Number(com.id) === Number(company)
                )?.name,
                nl: nlList?.find((n) => Number(n.id) === Number(nl))?.lable,
                year,
                quarter,
                cid: company,
                nlid: nl,
              }),
            }}
            target="_blank"
            rel="noopener noreferrer"
            disabled={
              year === "" ||
              quarter === "" ||
              nl === "" ||
              company === "" ||
              ![1, 2, 3, 4, 15, 16, 17].includes(Number(nl))
            }
            // className="right"
          >
            <Button
              disabled={
                year === "" ||
                quarter === "" ||
                nl === "" ||
                company === "" ||
                ![1, 2, 3, 4, 15, 16, 17, 40, 56].includes(Number(nl))
              }
              style={{ marginLeft: 10 }}
              variant="contained"
            >
              Fix Raw Data
              <Icon>open_in_new</Icon>
            </Button>
          </Link>
        </div>
        <div className="row">
          <Link
            to={{
              pathname: queryBuilder("/nl/force/map", {
                company: companies?.find(
                  (com) => Number(com.id) === Number(company)
                )?.name,
                nl: nlList?.find((n) => Number(n.id) === Number(nl))?.lable,
                year,
                quarter,
                cid: company,
                nlid: nl,
              }),
            }}
            target="_blank"
            rel="noopener noreferrer"
            disabled={
              year === "" || quarter === "" || nl === "" || company === ""
            }
            // className="right"
          >
            <Button
              disabled={
                year === "" || quarter === "" || nl === "" || company === ""
              }
              variant="contained"
            >
              Force Map
              <Icon>open_in_new</Icon>
            </Button>
          </Link>
        </div>
        {/* <div className="row">
          <Link
            to={{
              pathname: queryBuilder("/nl/data/raw/dynamic/fix", {
                company: companies?.find(
                  (com) => Number(com.id) === Number(company)
                )?.name,
                nl: nlList?.find((n) => Number(n.id) === Number(nl))?.lable,
                year,
                quarter,
                cid: company,
                nlid: nl,
              }),
            }}
            target="_blank"
            rel="noopener noreferrer"
            disabled={
              year === "" ||
              quarter === "" ||
              nl === "" ||
              company === "" ||
              ![1, 2, 3, 4, 15, 16, 17].includes(Number(nl))
            }
            // className="right"
          >
            <Button
              disabled={
                year === "" ||
                quarter === "" ||
                nl === "" ||
                company === "" ||
                ![1, 2, 3, 4, 15, 16, 17].includes(Number(nl))
              }
              style={{ marginLeft: 10 }}
              variant="contained"
            >
              Fix Dynamic Raw Data
              <Icon>open_in_new</Icon>
            </Button>
          </Link>
        </div> */}
      </div>
    </div>
  );
}
