import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import EditNl from "./EditNl";
import M from "materialize-css";
import { fetchNLData, setReducer } from "../../../store/action/nlFixAction";
import { getQuery, shapeData } from "../../../utils/nlHelper";
import EditDynamicNL from "./EditDynamicNL";

export default function NLData(props) {
  const NUM_OF_COL = 8;
  const dispatch = useDispatch();
  const location = useLocation();

  const [offset, setOffset] = useState({
    min: 0,
    max: NUM_OF_COL,
  });
  const [state, setState] = useState(null);

  const nlDataFetchLoading = useSelector(
    (state) => state.nlFix?.nlDataFetchLoading,
  );
  const nlDataFetchError = useSelector(
    (state) => state.nlFix?.nlDataFetchError,
  );
  const nlDataFetchSuccess = useSelector(
    (state) => state.nlFix?.nlDataFetchSuccess,
  );
  const isDynamic = useSelector((state) => state.nlFix?.isDynamic);
  const uid = useSelector((state) => state.auth?.uid);
  const nlDataData = useSelector((state) => state.nlFix?.nlDataData);
  const accessToken = useSelector((state) => state.auth?.accessToken);

  const [editData, setEditData] = useState(null);
  const [trackData, setTrack] = useState(null);
  const [keys, setKeys] = useState([]);
  const [tabbleData, setTableData] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    let tem = getQuery(location?.search);
    setState(tem);
    getNLData(tem);
    let elem = document.getElementById("edit-modal");
    M.Modal.init(elem, { preventScrolling: false });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    // console.log("SCROLL", e);
    // let elem = document.getElementById("tab-head");
    // elem.scroll({
    //   left: 20,
    // });
  };

  const getNLData = (query) => {
    const year = query?.year || state?.year;
    const quarter = query?.quarter || state?.quarter;
    const cid = query?.cid || state?.cid;
    const nlid = query?.nlid || state?.nlid;
    dispatch(
      fetchNLData(
        { year: year, quarter: quarter, cid: cid, nlid: nlid },
        accessToken,
      ),
    );
  };

  useEffect(() => {
    if (nlDataFetchSuccess && nlDataData) {
      let myData = shapeData(nlDataData);
      console.log("myData", myData);
      setTableData(myData);
      if (myData && myData[0]) {
        let keys = Object.keys(myData[0]);
        // console.log("KEYS BEFORE SORT", keys);
        let newKeys = keys.sort((a, b) =>
          (myData[0][a].colOrder || 999) > (myData[0][b].colOrder || 999)
            ? 1
            : -1,
        );
        newKeys = newKeys?.filter(
          (key) =>
            !["company name", "particulars", "source"].includes(
              key.toLowerCase(),
            ),
        );
        // console.log(
        //   "KEYS AFTER SORT",
        //   newKeys,
        //   newKeys.map((key) => {
        //     return { key, order: myData[0][key].colOrder };
        //   }),
        // );
        setKeys(newKeys);
      }
      // dispatch(setReducer({ nlDataFetchSuccess: false }));
      setTrack(null);
      dispatch(
        setReducer({
          nlDataData: null,
          nlDataFetchSuccess: false,
        }),
      );
    }
  }, [nlDataFetchSuccess]);

  const onEdit = (e, data) => {
    const year = state?.year;
    const quarter = state?.quarter;
    const cid = state?.cid;
    const nlid = state?.nlid;
    console.log("data", data);

    if (data?.rowId && data?.colId) {
      setEditData({
        ...data,
        year: year,
        quarter: quarter,
        cid: cid,
        nlid: nlid,
        id: e.target.id,
      });
      let elem = document.getElementById("edit-modal");
      var instance = M.Modal.getInstance(elem);
      if (!instance?.isOpen) {
        instance?.open();
        let elem = document.getElementById("value");
        if (elem) {
          elem.focus();
        }
      }
    }
  };

  const changeTrackData = (id, value) => {
    let temp = { ...trackData };
    let row_id = id?.split("-")[0];
    let index = tabbleData.findIndex((t) => String(t.id) === String(row_id));
    let key = id?.split("-")[1];
    let tempMyData = [...tabbleData];
    if (tempMyData && tempMyData[index] && tempMyData[index][key]) {
      tempMyData[index][key].value = value;
      console.log("CHANGE", index, key);
    }
    setTrack({ ...temp, [id]: true });
    setTableData([...(tempMyData || [])]);
  };

  const changeOffset = (type) => {
    let temp = { ...offset };
    if (type === "left") {
      if (temp.min > 0) {
        temp.min -= NUM_OF_COL;
        temp.max -= NUM_OF_COL;
      }
    } else {
      if (temp.max < keys?.length) {
        temp.min += NUM_OF_COL;
        temp.max += NUM_OF_COL;
      }
    }
    setOffset(temp);
  };

  console.log("offset", window.innerWidth);
  console.log(
    "table",
    tabbleData,
    nlDataData,
    "loading ",
    nlDataFetchLoading,
    "success ",
    nlDataFetchSuccess,
    nlDataFetchError,
  );
  if (!uid) return <Redirect to="/SignIn" />;

  if (nlDataFetchLoading || !tabbleData) {
    return (
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    );
  }

  if (isDynamic) {
    return (
      <EditDynamicNL
        data={tabbleData}
        text={state?.company + "---" + state?.nl?.toUpperCase()}
        year={state?.year}
        quarter={state?.quarter}
        cid={state?.cid}
        nlid={state?.nlid}
      />
    );
  } else {
    return (
      <div>
        <div id="edit-modal" class="modal">
          <div class="modal-content">
            <EditNl data={editData} changeTrackData={changeTrackData} />
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: 8, fontSize: 20, marginBottom: 0 }}
        >
          <div className="col s1">
            <button
              className="btn-floating btn"
              disabled={offset?.min === 0}
              onClick={() => {
                changeOffset("left");
              }}
            >
              <i className="material-icons">chevron_left</i>
            </button>
          </div>
          <div
            className="col s10 center center-align"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col s9">
              <strong>
                {state?.company + "---" + state?.nl?.toUpperCase()}
              </strong>
            </div>
            <div className="col s1">
              <button className="btn-floating btn" onClick={getNLData}>
                <i className="material-icons">refresh</i>
              </button>
            </div>
          </div>
          <div className="col s1 right-align">
            <button
              className="btn-floating btn"
              disabled={offset?.max === keys?.length}
              onClick={() => {
                changeOffset("right");
              }}
            >
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <input
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            placeholder="search here with row name"
          />
        </div>
        {keys.length === 0 ? (
          <p>
            <span style={{ marginLeft: "43%" }}>
              Data Not Loaded or no data found
            </span>
          </p>
        ) : (
          <table
            className="tableFixed centered striped"
            // style={{ marginTop: 50 }}
          >
            <thead>
              <tr id="tab-head" style={{ width: "100%" }}>
                {keys.map(
                  (d, i) =>
                    ((i >= offset.min && i < offset.max) ||
                      d === "rowName") && <th key={i}>{d}</th>,
                )}
              </tr>
            </thead>
            <tbody style={{ height: 490 }}>
              {tabbleData
                ?.filter((row) =>
                  row.rowName?.value?.toLowerCase()?.includes(query),
                )
                .sort((a, b) => {
                  return a.rowOrder - b.rowOrder;
                })
                .map((nlData, nlIndex) => (
                  <tr
                    id={"row-" + nlData?.rowOrder + "-" + nlIndex}
                    key={"row-" + nlData?.rowOrder + "-" + nlIndex}
                  >
                    {keys.map(
                      (k, i) =>
                        ((i >= offset.min && i < offset.max) ||
                          k === "rowName") && (
                          <td
                            id={nlData.id + "-" + k}
                            style={
                              trackData && trackData[nlData.id + "-" + k]
                                ? {
                                    backgroundColor: "lightcoral",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                  }
                                : {
                                    border: "1px solid black",
                                  }
                            }
                            key={i}
                            title={k}
                            onClick={(e) => {
                              onEdit(e, nlData[k]);
                            }}
                          >
                            {k === "rowOrder" ? nlData[k] : nlData[k]?.value}
                          </td>
                        ),
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
