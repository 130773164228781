export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export function checkNumeric(text) {
  text = String(text).replace(/(,|-|\s)/g, "");
  console.log("TEXT", text);
  text = Number(text);
  if (!isNaN(text)) {
    return true;
  } else {
    return false;
  }
}

export function orderFilters(segments = []) {
  let lobs = segments?.map((lob) => {
    return lob?.toLowerCase()?.replace(/(\.|-|\s\s+)/g, "");
  });
  let newList = [];
  let doneIndex = [];
  let primaries = [
    "total",
    "overall",
    "motor od",
    "motor tp",
    "pa",
    "personal accident",
    "health",
  ];
  for (let i = 0; i < primaries.length; i++) {
    let primary = primaries[i];
    let ind = lobs.indexOf(primary);
    if (ind >= 0) {
      newList.push(segments[ind]);
      doneIndex.push(ind);
    }
  }

  for (let i = 0; i < lobs.length; i++) {
    if (!doneIndex.includes(i)) {
      newList.push(segments[i]);
    }
  }

  return newList;
}
