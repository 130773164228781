const initState = {
  fetchLifeLoading: false,
  fetchLifeSuccess: false,
  fetchLifeError: false,
  life: null,

  uploadLifePdfLoading: false,
  uploadLifePdfSuccess: false,
  uploadLifePdfError: false,
  uploadLifePdfMessage: null,

  fetchLifeDataLodaing: false,
  fetchLifeDataSuccess: false,
  fetchLifeDataError: false,
  lifeData: null,
  lifePathDetailsId: null,
  fetchLifeDataMessage: null,

  lifeUpdateDataloading: false,
  lifeUpdateDataSuccess: false,
  lifeUpdateDataError: false,
  lifeUpdateDataMessage: null,

  lifeDeleteLoading: false,
  lifeDeleteSuccess: false,
  lifeDeleteError: false,
  lifeDeleteMessage: null,
};

const lifeReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_LIFE_REDUCER":
      return {
        ...state,
        ...action.payload,
      };
    case "LIFE_FETCH_LOADING":
      return {
        ...state,
        fetchLifeLoading: true,
        fetchLifeSuccess: false,
        fetchLifeError: false,
        life: null,
      };
    case "LIFE_FETCH_SUCCESS":
      return {
        ...state,
        fetchLifeLoading: false,
        fetchLifeSuccess: true,
        fetchLifeError: false,
        life: action.payload,
      };
    case "LIFE_FETCH_ERROR":
      return {
        ...state,
        fetchLifeLoading: false,
        fetchLifeSuccess: false,
        fetchLifeError: true,
        life: null,
      };
    case "UPLOAD_LIFE_PDF_LOADING":
      return {
        ...state,
        uploadLifePdfLoading: true,
        uploadLifePdfSuccess: false,
        uploadLifePdfError: false,
        uploadLifePdfMessage: null,
      };
    case "UPLOAD_LIFE_PDF_SUCCESS":
      return {
        ...state,
        uploadLifePdfLoading: false,
        uploadLifePdfSuccess: true,
        uploadLifePdfError: false,
        uploadLifePdfMessage: action.message,
      };
    case "UPLOAD_LIFE_PDF_ERROR":
      return {
        ...state,
        uploadLifePdfLoading: false,
        uploadLifePdfSuccess: false,
        uploadLifePdfError: true,
        uploadLifePdfMessage: action.message,
      };
    case "FETCH_LIFE_DATA_LOADING":
      return {
        ...state,
        fetchLifeDataLodaing: true,
        fetchLifeDataSuccess: false,
        fetchLifeDataError: false,
        lifeData: null,
        lifePathDetailsId: null,
        fetchLifeDataMessage: null,
      };
    case "FETCH_LIFE_DATA_SUCCESS":
      return {
        ...state,
        fetchLifeDataLodaing: false,
        fetchLifeDataSuccess: true,
        fetchLifeDataError: false,
        lifeData: action.data,
        lifePathDetailsId: action.lifePathDetailsId,
        fetchLifeDataMessage: action.message,
      };
    case "FETCH_LIFE_DATA_ERROR":
      return {
        ...state,
        fetchLifeDataLodaing: false,
        fetchLifeDataSuccess: false,
        fetchLifeDataError: false,
        lifeData: null,
        lifePathDetailsId: null,
        fetchLifeDataMessage: action.message,
      };
    case "LIFE_UPDATE_DATA_LOADING":
      return {
        ...state,
        lifeUpdateDataloading: true,
        lifeUpdateDataSuccess: false,
        lifeUpdateDataError: false,
        lifeUpdateDataMessage: null,
      };
    case "LIFE_UPDATE_DATA_SUCCESS":
      return {
        ...state,
        lifeUpdateDataloading: false,
        lifeUpdateDataSuccess: true,
        lifeUpdateDataError: false,
        lifeUpdateDataMessage: action.message,
      };
    case "LIFE_UPDATE_DATA_ERROR":
      return {
        ...state,
        lifeUpdateDataloading: false,
        lifeUpdateDataSuccess: false,
        lifeUpdateDataError: true,
        lifeUpdateDataMessage: action.message,
      };
    case "DELETE_LIFE_DATA_LOADING":
      return {
        ...state,
        lifeDeleteLoading: true,
        lifeDeleteSuccess: false,
        lifeDeleteError: false,
        lifeDeleteMessage: null,
      };
    case "DELETE_LIFE_DATA_SUCCESS":
      return {
        ...state,
        lifeDeleteLoading: false,
        lifeDeleteSuccess: true,
        lifeDeleteError: false,
        lifeDeleteMessage: action.message || "Life data deleted.",
      };
    case "DELETE_LIFE_DATA_ERROR":
      return {
        ...state,
        lifeDeleteLoading: false,
        lifeDeleteSuccess: false,
        lifeDeleteError: true,
        lifeDeleteMessage: action.message || "Error to delete life data",
      };
    default:
      return state;
  }
};
export default lifeReducer;
